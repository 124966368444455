var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Pedidos en proceso "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.load,"search":_vm.search,"loading-text":"Cargando... por favor espere","headers":_vm.headers,"items":_vm.getRequestComputed,"no-data-text":"No hay datos disponible.","items-per-page":5},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm._f("date")(item.created))+" ")])]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('button',{staticClass:"btn btn-outline-primary text-width-bold contenedor",on:{"click":function($event){return _vm.showProducts(item)}}},[_c('span',[_vm._v("Ver pedido")])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","color":"orange"}},[_vm._v(" "+_vm._s(item.status)+" ")])]}}])})],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"780"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Productos del pedido")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading-text":"Cargando... por favor espere","headers":_vm.headersProduct,"items":_vm.products,"no-data-text":"No hay datos disponible.","items-per-page":5},scopedSlots:_vm._u([{key:"item.img",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"p-2"},[_c('v-img',{attrs:{"src":item.img,"alt":item.name,"width":"50px","height":"50px"}})],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }