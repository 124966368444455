<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Pedidos en proceso
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>

          <v-data-table
            :loading="load"
            :search="search"
            loading-text="Cargando... por favor espere"
            :headers="headers"
            :items="getRequestComputed"
            no-data-text="No hay datos disponible."
            :items-per-page="5"
            class="elevation-1"
          >
            <template v-slot:item.created="{ item }">
              <div>
                {{ item.created | date }}
              </div>
            </template>
            <template v-slot:item.id="{ item }">

               <button
                @click="showProducts(item)"
               
                class="btn btn-outline-primary text-width-bold contenedor"
              >
                <span>Ver pedido</span>
              </button>
            
              
            </template>
            <template v-slot:item.status="{ item }">
              <v-btn text color="orange">
                {{ item.status }}
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" max-width="780">
      <v-card>
        <v-card-title>Productos del pedido</v-card-title>
        <v-data-table
          loading-text="Cargando... por favor espere"
          :headers="headersProduct"
          :items="products"
          no-data-text="No hay datos disponible."
          :items-per-page="5"
          class="elevation-1"
        >
          <template v-slot:item.img="{ item }">
            <div class="p-2">
              <v-img
                :src="item.img"
                :alt="item.name"
                width="50px"
                height="50px"
              ></v-img>
            </div>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { formatDateNew } from "../../filters";
export default {
  filters: {
    date(value) {
      return formatDateNew(value);
    },
  },
  data() {
    return {
      load: true,
      search: "",
      dialog: false,
      products: [],
      headersProduct: [
        {
          text: "Imagen",
          align: "start",
          sortable: false,
          value: "img",
        },

        { text: "Articulo", value: "name" },
        { text: "Sexo", value: "category" },
        { text: "Categoria", value: "subCategory" },
        { text: "Sub Categoria", value: "itemSubCategory" },
        { text: "Cantidad", value: "quantity" },
        { text: "Color", value: "color" },
        { text: "Tamaño", value: "currentSize" },
      ],
      headers: [
        {
          text: "Codigo",
          align: "start",
          sortable: false,
          value: "idRequest",
        },

       
        { text: "Estado", value: "status" },
        { text: "Fecha", value: "created" },
        { text: "Detalles", value: "id" },
      ],
    };
  },
  beforeMount() {
    this.loadRequests();
  },
  computed: {
    ...mapGetters(["users", "requests"]),
    getRequestComputed() {
      return this.requests.filter(
        (r) => r.status.toLowerCase() != "completado"
      );
    },
  },
  methods: {
    ...mapActions(["getRequests"]),
    async loadRequests() {
      await this.getRequests();
      this.load = false;
    },
    showProducts(item) {
      this.dialog = true;
      this.products = item.products;
    },
  },
};
</script>
